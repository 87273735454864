import { IconSize } from "./types";

interface Props {
  size?: IconSize;
  className?: string;
}

export const MoonIcon = (props: Props) => {
  const { size = "md", className } = props;

  const sizes: Record<IconSize, string> = {
    sm: "w-4 h-4",
    md: "w-6 h-6",
    lg: "w-8 h-8",
  };

  return (
    <svg
      className={`${sizes[size]} ${className}`}
      stroke="currentFill"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
      ></path>
    </svg>
  );
};
