"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperationType = void 0;
var OperationType;
(function (OperationType) {
    OperationType["SEND"] = "send";
    OperationType["DEPOSIT"] = "deposit";
    OperationType["WITHDRAW"] = "withdraw";
    OperationType["SENDAUST"] = "send-aust";
})(OperationType = exports.OperationType || (exports.OperationType = {}));
